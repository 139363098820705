// Translated
// Migrated
<template>
  <div
    v-if="type !== 'inline'"
    class="inline-flex"
    :class="classes"
  >
    <div
      v-for="(i, index) in stars"
      :key="index"
      class="review-star flex"
    >
      <img
        v-if="score < i + 0.3"
        :src="empty"
        alt="empty star"
        :width="starSize"
        :height="starSize"
        loading="lazy"
      />
      <img
        v-else-if="score < i + 1"
        :src="half"
        alt="half star"
        :width="starSize"
        :height="starSize"
        loading="lazy"
      />
      <img
        v-else
        :src="full"
        alt="full star"
        :width="starSize"
        :height="starSize"
        loading="lazy"
      />
    </div>
  </div>
  <div
    v-else
    class="contents"
  >
    <template
      v-for="(i, idx) in stars"
      :key="idx"
    >
      <img
        v-if="score < i + 0.3"
        :src="empty"
        alt="empty star"
        :width="starSize"
        :height="starSize"
        loading="lazy"
      />
      <img
        v-else-if="score < i + 1"
        :src="half"
        alt="half star"
        :width="starSize"
        :height="starSize"
        loading="lazy"
      />
      <img
        v-else
        :src="full"
        alt="full star"
        :width="starSize"
        :height="starSize"
        loading="lazy"
      />
    </template>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    fullstars: {
      type: Boolean,
      default: false,
    },

    score: {
      type: Number,
      required: true,
    },

    size: {
      type: String,
      default: 'sm',
      validator: value => ['sm', 'lg'].includes(value),
    },

    classes: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'container',
      validator: value => ['container', 'inline'].includes(value),
    },

    isStar: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      stars: [...Array(5).keys()],
    }
  },

  computed: {
    starSize () {
      if (this.size === 'lg') {
        return 20
      }

      return 16
    },

    selectIcon () {
      return !this.$isSol || this.isStar
    },

    empty () {
      return this.selectIcon ? `/icons/star${this.fullstars ? '-box' : ''}-empty.svg` : '/icons/solresor-empty.svg'
    },

    half () {
      return this.selectIcon ? `/icons/star${this.fullstars ? '-box' : ''}-half.svg` : '/icons/solresor-half.svg'
    },

    full () {
      return this.selectIcon ? `/icons/star${this.fullstars ? '-box' : ''}-full.svg` : '/icons/solresor-full.svg'
    },
  },
})
</script>
